import { CircularProgress, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import axios from 'axios';
import SupersetGraph from './SupersetGraph';

export default function SupersetDashboard(props) {

    const { dashboardType, dashboardList = [], client_id = null, facility_ids = [], projectList = [],
        dashboardHeight, showDashboard, loadDashboard, regions = null, builings = null, sensorType = null, plantType = null } = props;
    const [showLoader, setShowLoader] = useState(false);
    const [supersetResourceList, setSupersetResourceList] = useState([]);
    const [supersetGuestToken, setSupersetGuestToken] = useState(null);

    useEffect(() => { 
        setShowLoader(true);
    }, [client_id])

    useEffect(() => {
        if (!loadDashboard && !showDashboard && dashboardType === 'ESG' && (facility_ids.length === 0 || dashboardList.length === 0 || projectList.length === 0 || showLoader)) {
            return;
        }
        setShowLoader(true);
        const params = {
            section: dashboardType,
            type: 'Dashboard',
            client_id: dashboardType !== 'Home' ? client_id : null,
            facility_ids,
            dashboard_ids: dashboardList,
            pids: projectList,
        }
        if (dashboardType === 'sensorHealth') {
            params.regions = regions;
            params.builings = builings;
            params.sensorType = sensorType;
            params.plantType = plantType;
        }
        axios.post('/api/v1/report/superset-embedd', params).then((resp) => {
            setShowLoader(false);
            const response = resp?.data?.data;
            if (!response?.guestToken) {
                return;
            }
            setSupersetGuestToken(response?.guestToken);
            setSupersetResourceList(response.resource_list);
        }).catch(ex => {
            setShowLoader(false);
            console.error(ex);
        });
    }, [showDashboard, loadDashboard]);

    return (
        <Grid>
            {showLoader && (
                <div className='w3-center' style={{ marginTop: 30 }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ display: showLoader ? 'none' : 'block' }} id="superset-container">
                {supersetResourceList?.map((item) => (
                    <SupersetGraph
                        key={`graph-${item.id}`}
                        dashboardHeight={dashboardHeight}
                        dashboardId={item.id}
                        dashboardType={dashboardType}
                        supersetGuestToken={supersetGuestToken}
                    />
                ))}
            </div>
        </Grid>
    )
}