import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProjects } from '../../Redux/actions';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import dayjs from 'dayjs';
import moment from 'moment';
import { getQatarToday } from '../../utils';
import { Button, CircularProgress, Grid } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import {isValidDate} from '../../utils/Common';


export default function ReportView({dPid, digiReportFromDate, digiReportToDate, isDigiReportView, generateReport, onReportGenerated }) {
    const dispatch = useDispatch();
    const { client_id, id } = useParams();
    const state = useSelector(st => st);
    const { currentUser: { uniqueId }, userProjectsList } = state
    const [errors, setErrors] = useState([])
    const [userProjects, setUserProjects] = useState([])
    const [htmlContent, setHtmlContent] = useState([])
    const [showLoader, setShowLoader] = useState(true);
    const minDate = dayjs().subtract(180, 'days').toDate();
    const maxDate = dayjs().subtract(1, 'days').toDate();
    const [pidNotFound, setPidNotFound] = useState(false);
    
    const [reportFromDate, setReportFromDate] = useState(
        dayjs().subtract(14, 'days').toDate()
    );
    const [reportToDate, setReportToDate] = useState(maxDate);
    const projectId = id ? id : dPid

    useEffect(() => {
        if (isNaN(projectId) || /[!@#$%^&*(),.?":{}|<>]/g.test(projectId.toString()) || /\D/.test(projectId.toString())) {
            setPidNotFound(true)
        }
        dispatch(getUserProjects(client_id)).then((res) => {
            if (res && res.status === 200) {
                const projects = res?.data?.data
                if (projects?.length > 0) {
                    setUserProjects(projects)
                    populateReportTableData(digiReportFromDate ? digiReportFromDate : reportFromDate, digiReportToDate ? digiReportToDate : reportToDate);
                }
            }
            if (onReportGenerated) {
                onReportGenerated();
            }
        });
    }, [1, generateReport]);

    useEffect(() => {
        const divElements = document.getElementsByClassName('trigger-pdf-save');
        if (divElements?.length > 0) {
            for (const divElement of divElements) {
                divElement.addEventListener('click', handleSaveReport);
            }

            return () => {
                for (const divElement of divElements) {
                    divElement.removeEventListener('click', handleSaveReport);
                }
            };
        }
    }, [htmlContent]);

    useEffect(() => {
        const downloadElements = document.getElementsByClassName('trigger-data-download');
        for (const downloadElement of downloadElements) {
            downloadElement.addEventListener('click', handleDownloadData);
        }

        return () => {
            for (const downloadElement of downloadElements) {
                downloadElement.removeEventListener('click', handleDownloadData);
            }
        };

    }, [htmlContent]);

    // useEffect(() => {
    //     if (uniqueId === 'rtm.villa@green.in') {
    //         setReportFromDate(getQatarToday())
    //         setReportToDate(getQatarToday())
    //     }
    // }, [uniqueId]);

    const handleSaveReport = () => {
        const css = '@page { size: landscape; }';
        const head = document.head || document.getElementsByTagName('head')[0];
        const style = document.createElement('style');
        style.media = 'print';
        if (style.styleSheet) {
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }
        head.appendChild(style);
        window.print()
    }

    const handleDownloadData = () => {
        const params = {
            'pid': projectId,
            'report_from_date': reportFromDate,
            'report_to_date': reportToDate,
            'client_id': client_id
        }
        axios.get(`/papi/v1/download-reports-data/`, { params })
            .then((response) => {
                const data = response.data
                const blob = new Blob([data]);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "data.csv";
                link.click();
            })
            .catch((error) => {
                setHtmlContent('<div>Not available</div>')
                console.error('Error fetching HTML:', error);
            });
    }

    const validateForm = () => {
        let formIsValid = true;
        let err = { ...errors };

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const fromDate = reportFromDate ? new Date(reportFromDate) : null;
        if (fromDate) {fromDate.setHours(0, 0, 0, 0);}

        const toDate = reportToDate ? new Date(reportToDate) : null;
        if (toDate) {toDate.setHours(0, 0, 0, 0);}

        if (!reportFromDate) {
            formIsValid = false;
            err.reportFromDate = 'Please select report From Date';
        }

        if (!reportToDate) {
            formIsValid = false;
            err.reportToDate = 'Please select report To Date';
        }

        if (moment(reportFromDate).format('YYYY-MM-DD') > moment(reportToDate).format('YYYY-MM-DD')) {
            formIsValid = false;
            err.reportFromDate = 'From Date should be less than To Date';
        }

        if (!isValidDate(reportFromDate)) {
            formIsValid = false;
            err.reportFromDate = 'Please enter a valid date in the format DD/MM/YYYY';
        }

        if (!isValidDate(reportToDate)) {
            formIsValid = false;
            err.reportToDate = 'Please enter a valid date in the format DD/MM/YYYY';
        }

        if(fromDate >= today) {
            formIsValid = false;
            err.reportFromDate = "From Date should be less than today's Date";
        }

        if(toDate >= today) {
            formIsValid = false;
            err.reportToDate = "To Date should be less than today's Date";
        }
        
        setErrors(err);
        return formIsValid;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setHtmlContent('<div style="margin-top: 10px">No Data Found</div>')
        const valid = validateForm();
        if (!valid) {
            return;
        }
        populateReportTableData(reportFromDate, reportToDate);
    }

    const populateReportTableData = (reportFromDate, reportToDate) => {
        setShowLoader(true);
        const params = {
            'pid': projectId,
            'report_from_date': moment(reportFromDate).format('YYYY-MM-DD'),
            'report_to_date': moment(reportToDate).format('YYYY-MM-DD'),
            'client_id': client_id
        }
        axios.get(`/papi/v1/populate-report-table/`, { params })
            .then((response) => {
                
                if (response?.data) {
                    setHtmlContent(response.data);
                    setShowLoader(false);
                } else {
                    setHtmlContent('<div style="margin:12px 0 20px 0; font-size: 16px">Not available</div>')
                    setShowLoader(false);
                }
            })
            .catch((error) => {
                setShowLoader(false);
                setHtmlContent('<div style="margin:12px 0 20px 0; font-size: 16px">Not available</div>')
                console.error('Error fetching HTML:', error);
            });
    }

    if (pidNotFound) {
        return (<Grid container >
            <Grid item md={12} className='w3-padding' style={{ display: 'flex', justifyContent: 'center', height: '500px',  alignItems: 'center' }}>
                <h5 className='w3-text-grey'>
                    No Data Found
                </h5>
            </Grid>
        </Grid>
        );
    }

    if (userProjectsList?.isFetching) {
        return
    }

    return (
        <div className="main w3-padding-large" style={{ marginTop: 20 }}>
            {(!showLoader && userProjects.length === 0) ? (
                <div className='no-data-msg'>
                    Report is not yet added
                </div>
            ) : (
                <div className='report-view'>
                    <div className="reports-header hide-in-print">
                        {!( isDigiReportView ) && (<Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={4}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en'}>
                                    <DatePicker
                                        label="From Date *"
                                        dateFormat="DD/MM/YYYY"
                                        format="DD/MM/YYYY"
                                        required
                                        className='report-view-calendar-icon'
                                        slotProps={{ textField: { fullWidth: true, error: false, size: 'small' } }}
                                        name="reportFromDate"
                                        value={dayjs(reportFromDate)}
                                        maxDate={dayjs(maxDate)}
                                        minDate={dayjs(minDate)}
                                        onChange={(newValue) => {
                                            setReportFromDate(dayjs(newValue).toDate());
                                            setErrors({ ...errors, reportFromDate: null, reportToDate: null });
                                        }}
                                    />
                                     {errors.reportFromDate && <div className='error-text w3-margin-bottom'>{errors.reportFromDate}</div>}
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={6} sm={6} md={4}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en'}>
                                    <DatePicker
                                        label="To Date *"
                                        dateFormat="DD/MM/YYYY"
                                        format="DD/MM/YYYY"
                                        required
                                        className='report-view-calendar-icon'
                                        slotProps={{ textField: { fullWidth: true, error: false, size: 'small' } }}
                                        name="reportToDate"
                                        value={dayjs(reportToDate)}
                                        minDate={dayjs(reportFromDate) ? dayjs(reportFromDate) : dayjs(minDate)}
                                        maxDate={dayjs(maxDate)}
                                        onChange={(newValue) => {
                                            setReportToDate(dayjs(newValue).toDate());
                                            setErrors({ ...errors, reportToDate: null, reportFromDate: null });
                                        }}
                                    />
                                </LocalizationProvider>
                                {errors.reportToDate && <div className='error-text w3-margin-bottom'>{errors.reportToDate}</div>}
                            </Grid>
                            <Grid item xs={6} sm={6} md={2}>
                                <Button
                                    size='small'
                                    color='primary'
                                    style={{padding: 7}}
                                    variant="contained"
                                    onClick={handleSubmit}
                                >
                                    Generate Report
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={6} md={2}>
                                <Button
                                    size='small'
                                    color='primary'
                                    style={{ padding: 7, backgroundColor: 'white' }}
                                    variant="outlined"
                                    onClick={handleSaveReport}
                                >
                                    Save as PDF
                                </Button>
                            </Grid> 
                        </Grid>)}
                    </div>
                    {showLoader ? (
                        <div className='w3-center' style={{ marginTop: 30 }}>
                            <CircularProgress />
                        </div>
                    ) :
                        <div className='graph-container' dangerouslySetInnerHTML={{ __html: htmlContent }} />
                    }
                </div>
            )}
        </div >
    )
}