import Grid from '@mui/material/Grid';
import UserTable from '../Common/UserTable';
import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TextField, CircularProgress } from '@mui/material';
import InviteModal from "./InviteModal";
import FilterSidebar from "./FilterSidebar";
import { getAllInvite, deleteInvite, resendInvite, getJoinedGreenUsers, getAllClientUsers, deactivateAuthUser, activateAuthUser, setStoreData } from '../../Redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { Tooltip, IconButton, Chip } from '@mui/material';
import { red, green } from '@mui/material/colors';
import { Edit, Delete, Refresh } from '@mui/icons-material';

import AlertDialog from '../Common/AlertDialog';
import ConfirmDialog from '../Common/ConfirmDialog';
import SearchBox from '../Common/SearchBox';
import { Can } from '../../Context/Can'
import { DeleteIcon } from '../Common/TableUI';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copy from 'copy-to-clipboard';

export default function Users() {

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [userData, setUserData] = useState(null);
    const [clientUsers, setClientUsers] = useState(null);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [showDeactivateDialog, setShowDeactivateDialog] = useState(false);
    const [showActivateDialog, setShowActivateDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [alertMessage, setAlertMessage] = useState();
    const [msgType, setMsgType] = useState("success");
    const [showModal, setShowModal] = useState(null);
    const [open, setOpen] = useState(false);
    const [editData, setEditData] = useState(null);
    const [showLoader, setShowLoader] = useState(false)

    const showDeleteIcon = ['Invited', 'Inviting', 'Invite-Error']
    const [queryParams, setQueryParams] = useSearchParams();
    const [hoveredRowId, setHoveredRowId] = useState(null);
    const [isCopied, setIsCopied] = useState(false);

    const state = useSelector(st => st);
    const { user } = state;

    const fetchData = () => {
        const params = {
            title: queryParams.get("search")
        }
        setShowLoader(true)
        if (queryParams.get('tab') === 'invited') {
            dispatch(getAllInvite(params)).then((res) => {
                if (res && res.status === 200) {
                    const respData = res.data
                    if (!respData) {
                        return;
                    }
                    setUserData(respData.data)
                    setShowLoader(false)
                }
            })
            return
        } else if (queryParams.get('tab') === 'joined') {
            dispatch(getJoinedGreenUsers(params)).then((res) => {
                if (res && res.status === 200) {
                    const respData = res.data
                    if (!respData) {
                        return;
                    }
                    setUserData(respData.data)
                    setShowLoader(false)
                }
            })
        } else if (queryParams.get('tab') === 'client-users') {
            dispatch(getAllClientUsers(params)).then((res) => {
                if (res && res.status === 200) {
                    const respData = res.data
                    if (!respData) {
                        return;
                    }
                    setClientUsers(respData.restructuredData)
                    setShowLoader(false)
                }

            })
        }

    }

    useEffect(() => {
        if (!queryParams.get('tab')) {
            setQueryParams({ tab: 'joined' });
        }

        fetchData();
    }, [ queryParams]);

    const deleteUserInvite = () => {
        setShowConfirmDialog(false);
        dispatch(deleteInvite(selectedItem)).then((res) => {
            fetchData();
        })
    }

    const triggerDelete = (client) => {
        setShowConfirmDialog(true);
        setSelectedItem(client);
    }

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleDialogClose = () => {
        setAlertMessage(null);
        navigate('/user-management');
    };

    const handleModalMsg = (msg, type) => {
        setAlertMessage(msg);
        setMsgType(type);
    }


    const updateQueryParams = (params) => {
        const nParams = Object.assign({}, queryParams, params);
        setQueryParams(nParams);
    }

    const handleSearch = (value) => {
        updateQueryParams({ search: value, tab: queryParams.get('tab') });
    }

    const handleResend = (user) => {
        dispatch(resendInvite(user)).then((res) => {
        })

    }

    const handleChangeTab = (event, newValue) => {
        // setCurrentTab(newValue);
        updateQueryParams({ tab: newValue });
    };

    const handleDeactivateButton = ( id, emailId ) => {
        setSelectedItem({ id, emailId });
        setShowDeactivateDialog(true)

    }

    const handleActivateButton = ( id, emailId ) => {
        setSelectedItem({ id, emailId });
        setShowActivateDialog(true)

    }

    const handleDeactivateUser = () => {
        setShowDeactivateDialog(false);
        dispatch(deactivateAuthUser(selectedItem)).then((res) => {
            updateResponse(res, res?.data?.message)
            fetchData();
        })
    }

    const handleActivateUser = () => {
        setShowActivateDialog(false);
        dispatch(activateAuthUser(selectedItem)).then((res) => {
            updateResponse(res, res?.data?.message)
            fetchData();
        })
    }

    const handleCopyInvite = (url) => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
          }, 2000);
        copy(url);
    };

    const getStatusClassName = (item) => {
        if (item?.authUser?.status === 'Inactive') {
            return 'w3-red';
        } else if (item.status === 'Joined') {
            return 'w3-green';
        } else if (item.status === 'Invited') {
            return 'w3-yellow';
        } else {
            return 'w3-red'; 
        }
    };

    const updateResponse = (resp, message) => {
        const res = resp && resp.data;
        let msg = res.message;
        let type = "fail";
        if (res && res.success) {
            msg = message;
            type = "success";
        }
        dispatch(setStoreData('alertMessage', {
            msg, type,
            handleClose: () => handleCloseDialog(),
        }))
    };

    const handleCloseDialog = () => {
        dispatch(setStoreData('alertMessage', null))
    }

    let userList = []

    if (showLoader) {
        userList = (
            <TableRow>
                <TableCell colSpan={6} align="center">
                    <CircularProgress />
                </TableCell>
            </TableRow>
        )
    } else if (userData?.length > 0 && (queryParams.get('tab') === 'joined' || queryParams.get('tab') === 'invited')) {
        userList = (userData.map((item,index) => (<TableRow key={item?.id}
            onMouseEnter={() => {
                setHoveredRowId(item?.id);
            }}
            onMouseLeave={() => {
                setHoveredRowId(null);
            }}
        >
            <TableCell align="left">{index + 1}</TableCell>
            <TableCell align="left">{item?.name}</TableCell>
            <TableCell align="left">{queryParams.get('tab') === 'invited' ? item?.emailId : item?.user_id}</TableCell>
            <TableCell align="left">{item?.role}</TableCell>
            <TableCell align="left">
                <Chip
                    size="small"
                    className={getStatusClassName(item)}
                    label={item?.authUser?.status === 'Inactive' ? 'Deactivated' : item.status}
                />
            </TableCell>
            <TableCell align="right">

                <Can do="manage" on="UserEdit">
                    <Tooltip title="Edit">
                        <IconButton aria-label="edit"
                            style={{ opacity: hoveredRowId === item?.id ? 1 : 0, paddingLeft: '0px' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowModal(true);
                                setOpen(true)
                                setEditData(item)
                            }}>
                            <Edit className="cursor-pointer"
                                style={{ color: 'black' }}
                            />
                        </IconButton>
                    </Tooltip>
                </Can>
                <Can do="manage" on="UserDelete">
                    {(showDeleteIcon.includes(item?.status)) && <Tooltip title="Delete">
                        <IconButton aria-label="delete"
                            style={{ opacity: hoveredRowId === item?.id ? 1 : 0 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                triggerDelete(item?.id)
                            }}> 
                            <DeleteIcon/>

                        </IconButton>
                    </Tooltip>}
                </Can>


                {item?.status === 'Invite-Error' && <Tooltip title="Resend Email">
                    <IconButton aria-label="resend"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleResend(item);
                        }}>
                        <Refresh className="cursor-pointer"
                            style={{ color: green[500] }}
                        />
                    </IconButton>
                </Tooltip>}

                {item?.status === 'Invited' && (
                    <>
                        <Tooltip 
                        title={isCopied ? 'Link copied!' : 'Copy invite link'}
                        arrow
                        >
                            <IconButton aria-label="copy"
                                style={{ opacity: hoveredRowId === item?.id ? 1 : 0 }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    const url = window.location.href;
                                    const parsedUrl = new URL(url);
                                    const host = parsedUrl.origin;
                                    handleCopyInvite(host +`/invite/${item?.token}`);
                                }}> 
                                <ContentCopyIcon/>
                            </IconButton>
                        </Tooltip>
                    </>
                )}
            </TableCell>
        </TableRow>)))
    } else if ( clientUsers?.length > 0 && queryParams.get('tab') === 'client-users' ) {
       
        userList = (clientUsers?.map((item,index) => (
            <TableRow
            onMouseEnter={() => {
                setHoveredRowId(item?.email);
            }}
            onMouseLeave={() => {
                setHoveredRowId(null);
            }}
            >
                <TableCell align="left">{index + 1}</TableCell>
                <TableCell align="left">{item?.name}</TableCell>
                <TableCell align="left">{item?.email}</TableCell>
                <TableCell align="left">
                    <ul style={{ paddingLeft: '20px', margin: 0 }}>
                        {item?.clients?.map((client, index) => (
                        <li key={index} style={{ marginBottom: '4px', whiteSpace: 'pre-wrap' }}>
                            {client.client}
                        </li>
                        ))}
                    </ul>
                </TableCell>
                <TableCell align="left">
                    <ul style={{ paddingLeft: '20px', margin: 0 }}>
                        {item?.facilities?.map((facility, index) => (
                        <li key={index} style={{ marginBottom: '4px', whiteSpace: 'pre-wrap' }}>
                            {facility}
                        </li>
                        ))}
                    </ul>
                </TableCell>
                <TableCell align="center">
                    <Chip
                        variant="filled" 
                        size="small"
                        label={item?.authStatus === 'ACTIVE' ? 'ACTIVE' : 'DEACTIVATED'}
                        color={item?.authStatus === 'ACTIVE' ? 'success' : 'error'}
                    />
                </TableCell>
                <TableCell align="center" style={{ opacity: hoveredRowId === item?.email ? 1 : 0 }}>
                    <Can do="manage" on="ActivateAndDeactivateUser">
                    {user?.data?.user?.uniqueId !== item.email && (
                        <>
                        {item.authStatus === 'ACTIVE' ? (
                            <Button onClick={() => handleDeactivateButton(item.id, item.email)}>
                            Deactivate
                            </Button>
                        ) : (
                            <Button onClick={() => handleActivateButton(item.id, item.email)}>
                            ACTIVATE
                            </Button>
                        )}
                        </>
                    )}
                    </Can>
                </TableCell>
            </TableRow>
        )))
    } else {
        userList = (
            <TableRow>
                <TableCell colSpan={6} align="center">
                    <h5 className='w3-text-grey'>
                        No Data Found
                    </h5>
                </TableCell>
            </TableRow>
        )
    }

    return (
        <Grid container >
            <Grid item container spacing={8} paddingY={2} paddingX={3} >
                <Grid item  md={6}>
                    <Can do="manage" on="UserInvite">
                        <Button
                            variant="contained"
                            onClick={handleOpen}
                        >Invite User</Button>
                    </Can>
                </Grid>
                <Grid item md={6} container justifyContent="flex-end" direction="row">
                    <h6 style={{ paddingRight: '5px', fontSize: '15px' }}>Search By: </h6>
                    <SearchBox
                        value={queryParams.get('search') || ''}
                        placeholder={(queryParams.get('tab') === 'client-users') ? 'User Name / Facility' : 'User Name'}
                        search={handleSearch}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Tabs value={queryParams.get('tab')} onChange={handleChangeTab} style={{ marginLeft: '24px' }}>
                    <Tab label="Joined Users" value="joined" />
                    <Tab label="Invited Users" value="invited" />
                    <Tab label="Client Users" value="client-users" />
                </Tabs>
            </Grid>
            <Grid item md={12}>
                <Grid item md={12} >
                    <div className='w3-padding-large'>
                    {(queryParams.get('tab') === 'invited' || queryParams.get('tab') === 'joined') && (
                        <TableContainer component={Paper} >
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" className='client-table-head'>Sl No.</TableCell>
                                        <TableCell align="left" className='client-table-head'>Name</TableCell>
                                        <TableCell align="left" className='client-table-head'>Email</TableCell>
                                        <TableCell align="left" className='client-table-head'>Role</TableCell>
                                        <TableCell align="left" className='client-table-head'>Status</TableCell>
                                        <TableCell align="left" className='client-table-head'></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {userList}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        )}
                        { queryParams.get('tab') === 'client-users' && (
                        <TableContainer component={Paper} >
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" className='client-table-head'>Sl No.</TableCell>
                                        <TableCell align="left" className='client-table-head'>Name</TableCell>
                                        <TableCell align="left" className='client-table-head'>Email</TableCell>
                                        <TableCell align="left" className='client-table-head'>Clients</TableCell>
                                        <TableCell align="left" className='client-table-head'>Facilities</TableCell>
                                        <TableCell align="center" className='client-table-head'>Status</TableCell>
                                        <TableCell align="left" className='client-table-head'></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {userList}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        )}
                    </div>
                </Grid>
            </Grid>
            {open && <div className='w3-padding-small'>
                <InviteModal fetchData={fetchData}
                    handleClose={() => {
                        setOpen(false)
                        setEditData(null)
                    }}
                    open={open}
                    editData={editData}
                    handleModalMsg={handleModalMsg} />
            </div>}
            {alertMessage ? (
                <AlertDialog
                    handleClose={() => {
                        setAlertMessage(null)
                        fetchData()
                        setOpen(false)
                    }}
                    message={alertMessage}
                    type={msgType}
                    handleDialogClose={() => {
                        handleDialogClose()
                        fetchData()
                    }}
                />) : null}
            {showConfirmDialog
                ? (
                    <ConfirmDialog
                        handleCancel={() => {
                            setSelectedItem({})
                            setShowConfirmDialog(false)
                        }}
                        handleOk={deleteUserInvite}
                        message={'Are you sure to delete this user?'}
                    />
                ) : null}
            {showDeactivateDialog
            ? (
                <ConfirmDialog
                    handleCancel={() => {
                        setSelectedItem({})
                        setShowDeactivateDialog(false)
                    }}
                    handleOk={handleDeactivateUser}
                    message={'Are you sure to deactivate this user?'}
                />
            ) : null}
            {showActivateDialog
            ? (
                <ConfirmDialog
                    handleCancel={() => {
                        setSelectedItem({})
                        setShowActivateDialog(false)
                    }}
                    handleOk={handleActivateUser}
                    message={'Are you sure to Activate this user?'}
                />
            ) : null}
        </Grid>
    )
}




