import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProjectsList } from '../../Redux/actions';
import { useParams } from 'react-router-dom';
import { Button, Checkbox, CircularProgress, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, styled } from '@mui/material';
import SupersetDashboard from '../AiDashboard/SupersetDashboard';

export default function SensorHealth({clientId}) {
    const dispatch = useDispatch();
    let { client_id } = useParams();
    if (clientId) {
        client_id = clientId
    }
    const state = useSelector(st => st);
    const { getAllProjectsList: allProjectsList } = state
    const [showDashboard, setShowDashboard] = useState(false);
    const [loadDashboard, setLoadDashboard] = useState(false);
    const [regions, setRegions] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [sensorTypes, setSensorTypes] = useState([]);
    const [plantTypes, setPlantTypes] = useState([]);
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [selectedBuildings, setSelectedBuildings] = useState([]);
    const [selectedSensorTypes, setSelectedSensorTypes] = useState([]);
    const [selectedPlantTypes, setSelectedplantTypes] = useState([]);

    useEffect(() => {
        dispatch(getAllProjectsList({ client_id })).then((res) => {
            const respData = res?.data?.data;
            if (!respData) {
                return
            }
            if (respData?.regions?.length > 0) {
                setRegions(respData?.regions);
                setBuildings(respData.buildings)
                setSensorTypes(respData.sensorTypes)
                setPlantTypes(respData.plantTypes)
            }
        })
    }, [1]);


    const handleDbChange = (event, name) => {
        const { target: { value } } = event;
        const newValue = typeof value === 'string' ? value.split(',') : value;
        if (name === 'region') {
            setSelectedRegions(newValue);
        } else if (name === 'building') {
            setSelectedBuildings(newValue);
        } else if (name === 'sensorType') {
            setSelectedSensorTypes(newValue);
        } else {
            setSelectedplantTypes(newValue)
        }
    }

    const handleShowDashboard = () => {
        setShowDashboard(true);
        setLoadDashboard(!loadDashboard);
    };

    if (allProjectsList?.isFetching) {
        return (
            <div className="main w3-padding-large">
                <Grid container spacing={2}>
                    <div className='w3-center' style={{ marginTop: 30 }}>
                        <CircularProgress />
                    </div>
                </Grid>
            </div>
        )
    }
    const isSubmitDisabled = selectedRegions?.length === 0 &&
        selectedBuildings?.length === 0 &&
        selectedSensorTypes?.length === 0 &&
        selectedPlantTypes?.length === 0

    return (
        <div>
            <div style={{margin: '40px 30px'}}>
                <Grid container spacing={2}>
                    <Grid item className='w3-margin-bottom'>
                        <h5 className="card-title">Client Facility Health</h5>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className='w3-margin-bottom'>
                    <Grid item>
                        <FormControl size='small' sx={{ width: '100%' }}>
                            <InputLabel id="region">Region</InputLabel>
                            <Select
                                labelId="region"
                                id="region"
                                className='white-bg'
                                multiple
                                value={selectedRegions}
                                onChange={(e) => handleDbChange(e, 'region')}
                                input={<OutlinedInput label="Region" />}
                                renderValue={(selected) => selected.length == regions.length ? 'All Regions' : selected.length + ' selected '}
                            >
                                {regions.map((item) => (
                                    <MenuItem key={item.region} label={item.region} value={item.region}>
                                        <Checkbox checked={selectedRegions.indexOf(item.region) >= 0} />
                                        <ListItemText primary={item.region} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl size='small' sx={{ width: '100%' }}>
                            <InputLabel id="demo-multiple-name-label">Type of Building</InputLabel>
                            <Select
                                labelId="demo-multiple-name-label"
                                id="building"
                                className='white-bg'
                                multiple
                                value={selectedBuildings}
                                onChange={(e) => handleDbChange(e, 'building')}
                                input={<OutlinedInput label="Building" />}
                                renderValue={(selected) => selected.length == buildings.length ? 'All Buildings' : selected.length + ' selected '}
                            >
                                {buildings?.map((item) => (
                                    <MenuItem key={item.segment} label={item.segment} value={item.segment}>
                                        <Checkbox checked={selectedBuildings.indexOf(item.segment) >= 0} />
                                        <ListItemText primary={item.segment} />
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl size='small' sx={{ width: '100%' }}>
                            <InputLabel id="chart">Type of Sensors</InputLabel>
                            <Select
                                labelId="chart"
                                id="sensorType"
                                className='white-bg'
                                multiple
                                value={selectedSensorTypes}
                                onChange={(e) => handleDbChange(e, 'sensorType')}
                                input={<OutlinedInput label="sensorType" />}
                                renderValue={(selected) => selected.length == sensorTypes.length ? 'All Sensor Types' : selected.length + ' selected '}
                            >
                                {sensorTypes.map((item) => (
                                    <MenuItem key={item.meter_group} label={item.meter_group} value={item.meter_group}>
                                        <Checkbox checked={selectedSensorTypes.indexOf(item.meter_group) >= 0} />
                                        <ListItemText primary={item.meter_group.replace('_', " ")} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl size='small' sx={{ width: '100%' }}>
                            <InputLabel id="chart">Type of Plant</InputLabel>
                            <Select
                                labelId="chart"
                                id="plantType"
                                className='white-bg'
                                multiple
                                value={selectedPlantTypes}
                                onChange={(e) => handleDbChange(e, 'plantType')}
                                input={<OutlinedInput label="plantType" />}
                                renderValue={(selected) => selected.length == plantTypes.length ? 'All Sensor Types' : selected.length + ' selected '}
                            >
                                {plantTypes.map((item) => (
                                    <MenuItem key={item.planttype} label={item.planttype} value={item.planttype}>
                                        <Checkbox checked={selectedPlantTypes.indexOf(item.planttype) >= 0} />
                                        <ListItemText primary={item.planttype} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            size='small'
                            style={{ cursor: isSubmitDisabled ? 'not-allowed' : 'pointer', height: '98%' }}
                            onClick={handleShowDashboard}
                            disabled={isSubmitDisabled}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </div>
            <SupersetDashboard
                regions={selectedRegions}
                builings={selectedBuildings}
                sensorType={selectedSensorTypes}
                plantType={selectedPlantTypes}
                client_id={client_id}
                dashboardType='sensorHealth'
                dashboardHeight="600px"
                showDashboard={showDashboard}
                loadDashboard={loadDashboard}
            />
        </div>
    )
}