
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createNewSiteVisitReport, deleteSiteVisitReport, getClientById, getProjectById, getJoinedGreenUsers, getSiteVisitReportById, deleteSiteVisitReportImage, getAllFacilityTickets, getProjectsFromFacility, getFacilityById } from '../../Redux/actions';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { TextField, FormControl, InputLabel, Select, MenuItem, Tooltip, IconButton, Autocomplete, ListItemText } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { CircularProgress } from '@mui/material';

import { updateSiteVisitReport } from '../../Redux/actions'

import AlertDialog from '../Common/AlertDialog';
import AddIcon from '@mui/icons-material/Add';

import CloseIcon from '@mui/icons-material/Close';

import { Can } from '../../Context/Can';
import { DeleteIcon } from '../Common/TableUI';
import ConfirmDialog from '../Common/ConfirmDialog';

import BackArrow from '../../Images/BackArrow.png';

import moment from 'moment';

import { isValidDate } from '../../utils/Common';

export default function SiteVisitForm() {

    const { id, client_id, site_visit_id, pid, facilityId } = useParams();

    const intialForm = {
        client_name: '',
        project_name: '',
    }

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [form, setForm] = useState(intialForm);
    const [greenUsers, setGreenUsers] = useState([])


    const error = {};
    const [errors, setErrors] = useState(error);

    const [uploadedImage, setUploadedImage] = useState();
    const [showConfirmUploadDialog, setShowConfirmUploadDialog] = useState(false);


    const [disableButton, setDisableButton] = useState(false);

    const [site_visit_date, setSiteVisitDate] = useState(null)

    const [showLoader, setShowLoader] = useState(false);

    const [selectedItem, setSelectedItem] = useState(null);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)


    const [alertMessage, setAlertMessage] = useState();
    const [uploadModalMessage, setUploadModalMessage] = useState();
    const [msgType, setMsgType] = useState("success");

    const [itemNotFound, setItemNotFound] = useState(false);

    const [newFieldsCount, setNewFieldsCount] = useState(0);
    const [dynamicFields, setDynamicFields] = useState([
        { cardId: 0,description: '', responsibleTeamTimeline: '', images: [] }
    ]);

    const [selectedImages, setSelectedImages] = useState([]);

    const siteVisitType = ['Ticket', 'Health Checkup', 'Installation', 'Feasibility', 'Invoice submission', 'RTM Training'];

    const maxDate = dayjs().toDate()
    const [uploadedFile, setUploadedFile] = useState({});

    const [selectedEngineerVisited, setSelectedEngineerVisited] = useState([]);
    const [facilityTickets, setFacilityTickets] = useState([])
    const [selectedFacilityTickets, setSelectedFacilityTickets] = useState([]);
    const [projectNotFound, setProjectNotFound] = useState(false);
    const [siteVisitNotFound, setSiteVisitNotFound] = useState(false);
    const [projectData, setProjectData] = useState([]);
    const [selectedPid, setSelectedPid] = useState(null);
    const [facilityNotFound, setFacilityNotFound] = useState(false);

    useEffect(() => {
        dispatch(getProjectsFromFacility( client_id, facilityId )).then((res) => {
            const response = res?.data?.data
            setProjectData(response)
        })
            
    },[])  

    useEffect(() => {
        const fetchData = async () => {
            setShowLoader(true);
            let greenUsersAvailable = null;
            let facTicket = [];

            if (client_id) {
                try {
                    const clientResponse = await dispatch(getClientById(client_id));
                    if (!clientResponse.data.success) {
                        return;
                    }
                    const clientName = clientResponse.data.data.name;
                    setForm(prevForm => ({
                        ...prevForm,
                        client_name: clientName
                    }));
                } catch (error) {
                    console.error("Error fetching client data:", error);
                }

                try {
                    const greenUsersResponse = await dispatch(getJoinedGreenUsers());
                    if (!greenUsersResponse.data.success) {
                        return;
                    }
                    greenUsersAvailable = greenUsersResponse.data.data;
                    const activeUsers = greenUsersAvailable?.filter(item => item.status != 'Inactive');
                    const users = activeUsers?.map(item => item.name)
                    setGreenUsers(users);
                } catch (error) {
                    console.error("Error fetching green users data:", error);
                }
            }

            if (pid) {
                await dispatch(getProjectById(client_id, pid)).then(resp => {
                    if (!resp.data.success) {
                        setProjectNotFound(true);
                        return
                    }
                    if (resp.data.projectData) {
                        const projectName = resp.data.projectData.projname;
                        const data = Object.assign({}, form);
                        if (resp.data.projectData) {
                            dispatch(getAllFacilityTickets(resp?.data?.projectData?.facility_id )).then(resp => {
                                if (!resp.data.success) {
                                    return
                                }
                                
                                
                                resp?.data?.data?.map(item => {
                                    if(!facTicket.includes(item)) {
                                        facTicket.push(item.id)
                                    }
                                })
                                const tickets = resp?.data?.data;
                                const ticektId = tickets?.map(item => item.id)
                                setFacilityTickets(ticektId)
                            })
                            setForm(prevForm => ({
                                ...prevForm,
                                project_name: projectName
                            }));
                        }
                    }
                })
            }

            if (site_visit_id) {
                await dispatch(getSiteVisitReportById(client_id, pid, site_visit_id)).then(resp => {
                    if (!resp?.data?.success) {
                        setSiteVisitNotFound(true)
                        return
                    }
                    const response = resp.data.data;
                    const { clientName, descriptionNew, engineerVisiting, reportTime, responsible_team, siteVisitType, ticketNumber, visitedDate, project, reportImages, attachment, media, dynamicFieldsData } = response;
                    if (visitedDate) {
                        setSiteVisitDate(visitedDate);
                    }
                    setForm(prevForm => ({
                        ...prevForm,
                        client_name: clientName,
                        project_name: project.projname,
                        descriptionNew,
                        engineer_visited: engineerVisiting,
                        reportTime,
                        responsible_team,
                        site_visit_type: siteVisitType.trim(),
                        ticket_numbers: ticketNumber,
                        visitedDate: visitedDate ? visitedDate : null,
                        attachment,
                        media
                    }));
                    let filteredUsers = [];
                    if (engineerVisiting) {
                        const engineerNames = engineerVisiting.split(',').map(name => name.trim());
                        engineerNames.forEach(name => {
                            if (!filteredUsers.includes(name)) {
                                filteredUsers.push(name);
                            }
                        });
                        setSelectedEngineerVisited(filteredUsers);
                       
                    }
                    let filteredTicket = [];
                    if (ticketNumber) {
                        const selectedTickets = ticketNumber?.split(',')?.map(id => id.trim());
                        const validTickets = selectedTickets?.filter(ticket => ticket.trim() !== null && ticket !== '' && !ticket.startsWith("null"));
                        if (validTickets?.length > 0) {
                            filteredTicket = facTicket?.filter(tickets => validTickets.includes(tickets.id));
                            if (filteredTicket.length === 0) {
                                filteredTicket = [...validTickets];
                            }
                            setSelectedFacilityTickets(filteredTicket);
                        } else {
                            setSelectedFacilityTickets([])
                        }
                    }

                    if (dynamicFieldsData) {
                        const newDataArray = dynamicFieldsData?.map((dfield, index) => {
                            const imageArr = [];
                            for (const dImages of dfield.images) {
                                imageArr.push({
                                    url: dImages?.url ? dImages?.url : dImages?.url?.url,
                                    signedUrl: dImages?.signedUrl
                                });
                            }
                        
                            const data = {
                                cardId: index,
                                description: dfield.description,
                                responsibleTeamTimeline: dfield.responsible_team,
                                images: imageArr
                            };
                            return data;
                        });
                        
                        
                        const newDynamicFields = dynamicFieldsData?.map((field, idx) => ({
                            cardId: idx,
                            description: field.description,
                            responsibleTeamTimeline: field.responsible_team,
                            images: field?.images?.map(image => ({
                                url: image?.url,
                                signedUrl:  image?.signedUrl
                            }))
                        }));
                        setDynamicFields(newDataArray);
                        setNewFieldsCount(newDynamicFields.length);
                    }
                    setShowLoader(false);
                })
            }
            if(facilityId) {
                dispatch(getFacilityById(client_id,facilityId)).then(resp => {
                    const response = resp?.data?.facilityData
                    if (!response) {
                        setFacilityNotFound(true);
                        return;
                    }
                })
            }
            setShowLoader(false);
        };

        fetchData();

    }, [id, client_id, site_visit_id, pid])

    const handleSelect = (e) => {
        if(disableButton) {
            setDisableButton(false);
        }
        const { name, value } = e.target;
        const data = Object.assign({}, form);
        data[name] = value;
        const err = Object.assign({}, errors);
        if (err[name]) {
            err[name] = null;
        }
        setErrors(err);
        if ( value !== 'Ticket' && name === 'site_visit_type') {
            setSelectedFacilityTickets([])
        }
        setForm(data)

    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        const data = Object.assign({}, form);
        data[name] = value;
        const err = Object.assign({}, errors);
        if (err[name]) {
            err[name] = null;
        }
        setErrors(err);
        setForm(data);
        if(disableButton) {
            setDisableButton(false)
        }
    }

    const triggerDelete = () => {
        setSelectedItem(site_visit_id);
        setShowConfirmDialog(true);
    }

    const handleDeleteSiteVisitReport = () => {
        if (selectedItem) {
            setShowConfirmDialog(false);
            dispatch(deleteSiteVisitReport(client_id, pid, selectedItem)).then((resp) => {
                setSelectedItem(null);
                if (resp?.data?.success) {
                    setAlertMessage(resp.data.message)
                    setMsgType('success')
                } else {
                    setAlertMessage(resp.data.message)
                    setMsgType('fail')
                }
            })
        }
    }

    const validateData = () => {
        const err = { ...errors };
        let hasErrors = false;

        const siteVisitDate = site_visit_date ? new Date(site_visit_date) : null;
        const today = new Date();
        today.setHours(0, 0, 0, 0); 
        if (siteVisitDate) {siteVisitDate.setHours(0, 0, 0, 0);}

        if ( facilityId && !form.project_name ) {
            hasErrors = true;
            err.project_name = 'This field is required'
        }

        if (!form.site_visit_type) {
            hasErrors = true;
            err.site_visit_type = 'This field is mandatory';
        }
        if (selectedEngineerVisited?.length === 0) {
            hasErrors = true;
            err.engineer_visited = 'This field is mandatory';
        }
        if (!site_visit_date) {
            hasErrors = true;
            err.site_visit_date = 'This field is mandatory';
        }
        if (!isValidDate(site_visit_date)) {
            hasErrors = true;
            err.site_visit_date = 'Please enter a valid date in the format DD/MM/YYYY';
        }

        if(siteVisitDate && (siteVisitDate > today)) {
            hasErrors = true;
            err.site_visit_date = "Site visit date should be less than or equal to today's date";
        }


        dynamicFields.forEach((field, index) => {
            if (field.description.trim() === "") {
                hasErrors = true;
                err[`description${index}`] = 'This field is mandatory';
            }
        });


        if (hasErrors) {
            setErrors(err);
            return false;
        }
        return true;
    };

    const handleSubmit = () => {
        setDisableButton(true)
        const checkForm = validateData();
        if (checkForm) {
            const description = dynamicFields?.map((field, index) => `${field.description}`).join('<br>');
            const responsibleTeamTimeline = dynamicFields?.map((field, index) => `${field.responsibleTeamTimeline}`).join('<br>');
            const engineerNames = selectedEngineerVisited?.map(engineer => engineer).join(', ');
            const ticketNumbers = selectedFacilityTickets?.map(tickets => tickets).join(', ');

            const newData = dynamicFields?.map(item => {
                return {
                    description: item.description,
                    responsible_team: item.responsibleTeamTimeline,
                    images: item?.images?.map(val => (
                        val.url
                    ))
                };
            });
            
            const dynamicData = dynamicFields?.map((field, index) => ({
                cardId: field.cardId,
                description: field.description,
                responsible_team: field.responsibleTeamTimeline,
                images: field?.images?.map((image, idx) => (
                   image.url
                ))
            }));
            const data = {
                client_id,
                clientName: form.client_name,
                pid: pid ? pid : selectedPid,
                ticketNumber: ticketNumbers,
                siteVisitType: form.site_visit_type,
                engineerVisiting: engineerNames,
                visitDate: site_visit_date,
                descriptionNew: description,
                responsible_team: responsibleTeamTimeline,
                imageAttachment: form.attachment,
                dynamicFieldsData: dynamicData
            }

            const formData = new FormData();
            formData.append('data', JSON.stringify(data))

            const cardIndexMap = {};

            Object.values(uploadedFile).forEach((files, index) => {
                files.forEach((file, idx) => {
                    formData.append(`imageFiles`, file);

                    if (!cardIndexMap[index]) {
                        formData.append(`cardIndex[${index}][${idx}]`, index);
                        cardIndexMap[index] = true;
                    }

                    formData.append(`imageIndex[${index}][${idx}]`, idx);
                });
            });

            Object.keys(uploadedFile).forEach(index => {
                const dynamicIndex = parseInt(index);
                if (!isNaN(dynamicIndex) && dynamicFields[dynamicIndex]) {
                    if (!dynamicData[dynamicIndex].images) {
                        dynamicData[dynamicIndex].images = [];
                    }
                    uploadedFile[index].forEach(file => {
                        dynamicData[dynamicIndex].images.push({
                            url: URL.createObjectURL(file),
                            signedUrl: null 
                        });
                    });
                }
            });


            if (site_visit_id) {
                dispatch(updateSiteVisitReport(site_visit_id, formData)).then(resp => {
                    if (resp?.data?.success) {
                        setAlertMessage(resp.data.message)
                        setMsgType('success')
                    } else {
                        setAlertMessage(resp.data.message)
                        setMsgType('fail')
                    }
                })
            } else if ( pid || facilityId ) {
                dispatch(createNewSiteVisitReport(formData)).then(resp => {
                    if (resp?.data?.success) {
                        setAlertMessage(resp.data.message)
                        setMsgType('success')
                    } else {
                        setAlertMessage(resp?.data?.message)
                        setMsgType('fail')
                    }
                })
            }

            if(site_visit_id && selectedImages?.length > 0) {
                dispatch(deleteSiteVisitReportImage(selectedImages, client_id, site_visit_id)).then(resp => {
                    console.log('resp: ', resp);

                })
            }
        }
    }

    const handleAddField = () => {
        setNewFieldsCount(newFieldsCount + 1);
        const nextCardId = dynamicFields[dynamicFields.length - 1].cardId;
        setDynamicFields([...dynamicFields, { cardId: nextCardId + 1,description: '', responsibleTeamTimeline: '', images: [] }]);
        if(disableButton) {
            setDisableButton(false)
        }
    };

    const handleDynamicFieldChange = (index, fieldPrefix, value) => {
        if(disableButton) {
            setDisableButton(false);
        }
        const err = Object.assign({}, errors);
        if (err[`${fieldPrefix}${index}`]) {
            err[`${fieldPrefix}${index}`] = null;
        }
        setErrors(err);

        const currentValue = form[`${fieldPrefix}${index}`] || '';
        const newValue = currentValue + value;
        const data = Object.assign({}, form);
        data[`${fieldPrefix}${index}`] = newValue;
        const updatedFields = [...dynamicFields];
        updatedFields[index][fieldPrefix] = value;
        setDynamicFields(updatedFields);
        setForm(data);
    }

    const handleConfirmUpload = () => {
        const formData = new FormData();
        if (uploadedImage) {
            formData.append('imageFile', uploadedImage);
            formData.append('type', 'image');
            formData.append('size', uploadedImage.size);
        }
        setShowConfirmUploadDialog(false)
    }

    const handleFileChange = (event, fieldIndex) => {
        if (event.target.files.length) {
            const files = event.target.files;
            let fileList = Array.from(files);

            const imageType = ['image/jpeg', 'image/png', 'image/jpg', 'image/heif']
            const invalidFiles = fileList.filter(file => !imageType.includes(file.type));
        if (invalidFiles.length > 0) {
            setUploadModalMessage('Unsupported file type. Please upload only image files.');
            setMsgType('fail');
            return;
        }

            const currentUploadedFiles = uploadedFile[fieldIndex]?.length || 0;
            const existingImagesCount = form?.media?.reduce((count, mediaItem) => {
                const { file_name, path } = mediaItem;
            
                const parts = file_name?.split('_');
                const pathParts = path?.split('/')[3]?.split('_');
                
                const imageIndex = parts[1] ? parseInt(parts[1], 10) : parseInt(pathParts[pathParts?.length - 1], 10);
            
                return imageIndex === fieldIndex ? count + 1 : count;
            }, 0);
            
            const totalFilesCount = currentUploadedFiles + (existingImagesCount ? existingImagesCount : 0);
            const remainingSlots = 5 - totalFilesCount;
            const currentField = dynamicFields[fieldIndex];

            if ((currentUploadedFiles + fileList?.length > 5) || (currentField?.images?.length + currentUploadedFiles + fileList?.length > 5)) {
                setUploadModalMessage('Maximum upload limit of 5 reached for this description');
                setMsgType('fail');
                fileList = fileList.slice(0, remainingSlots);
            }

            const imageNumber = currentUploadedFiles + fileList.length;

            const updatedFiles = fileList.map((file, index) => {
                const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
                let randomString = '';
                const charactersLength = characters?.length;

                for (let i = 0; i < 4; i++) {
                    randomString += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                const newFileName = `card_${fieldIndex}_${imageNumber + index}_${randomString}_${file.name}`;
                return new File([file], newFileName, { type: file.type });
            });

            const updatedUploadedFile = {
                ...uploadedFile,
                [fieldIndex]: uploadedFile[fieldIndex] ? [...uploadedFile[fieldIndex], ...updatedFiles] : updatedFiles
            };

            setUploadedFile(updatedUploadedFile);
        }
        event.target.value = ''
    };




    const handleRemoveField = (indexToRemove) => {
        if (newFieldsCount > 0 && dynamicFields.length > 1) {
            const updatedUploadedFile = { ...uploadedFile };
            delete updatedUploadedFile[indexToRemove];
            setUploadedFile(updatedUploadedFile);
            const updatedDynamicData = dynamicFields?.filter((item, index) => item.cardId !== indexToRemove);
            const updatedMedia = form?.media?.filter(mediaItem => {
                const fileNameParts = mediaItem.file_name.split('_');
                const imageIndex = parseInt(fileNameParts[1]);
                return imageIndex !== indexToRemove;
            });

            const err = { ...errors };
            if(err[`description${indexToRemove}`]) {
                err[`description${indexToRemove}`] = null;
            }
            setErrors(err);
            
            setForm(prevForm => ({
                ...prevForm,
                media: updatedMedia
            }));
            
            setDynamicFields(updatedDynamicData);
            setNewFieldsCount(newFieldsCount - 1);
            if(disableButton) {
                setDisableButton(false)
            }
        }
    };

    const handleEngineerVisitedAutoCompleteChange = (event, value) => {
        const err = Object.assign({}, errors);
        if (err['engineer_visited']) {
            err['engineer_visited'] = null;
        }
        setErrors(err)
        setSelectedEngineerVisited(value);
        if (disableButton) {
            setDisableButton(false)
        }
    }

    const handleDeleteImage = (fieldIndex, imageIndex) => {
        const updatedUploadedFile = { ...uploadedFile };
        updatedUploadedFile[fieldIndex] = updatedUploadedFile[fieldIndex]?.filter((_, idx) => idx !== imageIndex);
        setUploadedFile(updatedUploadedFile);
    };

    const handleDeleteStoredImage = (mediaItemId) => {
        const updatedDynamicFields = dynamicFields?.map(field => {
            const updatedImages = field.images?.filter(image => image.url !== mediaItemId.url);
            return {
                ...field,
                images: updatedImages
            };
        });

        const updatedMedia = form?.media?.filter(mediaItem => {
            return mediaItem.path !== mediaItemId.url;
        });
        setSelectedImages([...selectedImages, mediaItemId.url])
        setDynamicFields(updatedDynamicFields);
        setForm(prevForm => ({
            ...prevForm,
            media: updatedMedia
        }));
    };

    const handleTicketSelect = (event, value) => {
        if (disableButton) {
            setDisableButton(false)
        }
        const err = Object.assign({}, errors);
        if (err['ticket_numbers']) {
            err['ticket_numbers'] = null;
        }
        setErrors(err);
        setSelectedFacilityTickets(value);
    }

    const handleProjectChange = (event, newValue) => {
        const data = { ...form };
        data.project_name = newValue ? newValue?.projname : ''; 
        setForm(data);
        setSelectedPid(newValue?.pid);
        const err = Object.assign({}, errors);
        if (err['project_name']) {
            err['project_name'] = null;
        }
        setErrors(err);
        if(disableButton) {
            setDisableButton(false)
        }
    };


    if (showLoader) {
        return <div className="w3-center">
            <CircularProgress />
        </div>
    }

    if (site_visit_id && itemNotFound) {
        return <Grid container >
            <Grid item md={12} className='w3-padding' style={{ display: 'flex', justifyContent: 'center', height: '500px', alignItems: 'center' }}>
                <h5 className='w3-text-grey'>
                    Create Site Vist report not available due to invalid client id.
                </h5>
            </Grid>
        </Grid>

    }
    if(projectNotFound) {
        return <Grid container >
        <Grid item md={12} className='w3-padding' style={{ display: 'flex', justifyContent: 'center', height: '500px', alignItems: 'center' }}>
            <h5 className='w3-text-grey'>
                Invalid client or project id
            </h5>
        </Grid>
    </Grid>
    }
    if(site_visit_id && siteVisitNotFound) {
        return <Grid container >
        <Grid item md={12} className='w3-padding' style={{ display: 'flex', justifyContent: 'center', height: '500px', alignItems: 'center' }}>
            <h5 className='w3-text-grey'>
                Site visit report not found
            </h5>
        </Grid>
    </Grid>
    }
    if(facilityNotFound) {
        return <Grid container >
            <Grid item md={12} className='w3-padding' style={{ display: 'flex', justifyContent: 'center', height: '500px', alignItems: 'center' }}>
                <h5 className='w3-text-grey'>
                    Site visit report not found
                </h5>
            </Grid>
        </Grid>
    }
    return (<Paper elevation={1} style={{ margin: '16px ' }}>
        <Grid container >
            <Grid container className='w3-padding'>
                <Grid style={{ display: 'flex' }}>
                    <img id="back-arrow" className="cursor-pointer" width="20px" height="20px" onClick={() => navigate(-1)} src={BackArrow} alt="back-arrow" style={{ marginTop: '10px', marginRight: '5px' }} />
                    <Grid item md={12}>
                        <h6 style={{ paddingBottom: '8px' }}>{site_visit_id ? 'Edit Site Visit Report' : 'Create New Site Visit Report'}</h6>
                    </Grid>
                </Grid>
                <Grid container item md={12}>
                    <Grid item md={12} className="w3-margin-bottom" style={{ display: 'flex', flexDirection: 'row', }}>
                        <TextField
                            fullWidth
                            label="Client Name"
                            name="client_name"
                            required
                            disabled
                            value={form.client_name}
                            onChange={handleChange} />
                    </Grid>
                    {errors.client_name && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.client_name}</div>}
                    <Grid item md={12} className="w3-margin-bottom" style={{ display: 'flex', flexDirection: 'row', }}>
                            { (facilityId && !site_visit_id) ? (<Autocomplete
                                fullWidth
                                required
                                options={projectData}
                                getOptionLabel={(option) => `${option.projname}`}
                                value={projectData?.find((pid) => pid?.projname === form?.project_name) || null}
                                onChange={handleProjectChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Project Name"
                                        name="project_name"
                                        required
                                    />
                                )}
                            />) :
                            (<TextField
                            fullWidth
                            label="Project Name"
                            name="project_name"
                            disabled
                            required
                            value={form.project_name}
                            onChange={handleChange} />)}
                    </Grid>
                    {errors.project_name && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.project_name}</div>}


                    <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item md={6}>
                            <Autocomplete
                                fullWidth
                                multiple
                                required
                                options={greenUsers}
                                value={selectedEngineerVisited}
                                filterSelectedOptions
                                isOptionEqualToValue={(option, value) => option === value}
                                onChange={handleEngineerVisitedAutoCompleteChange}
                                getOptionLabel={(option) => option}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <ListItemText primary={option} />
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} 
                                    label="Engineer Visited *" 
                                    placeholder="Engineer Visited" />
                                )}
                            />

                            {errors.engineer_visited && <div className='error-text'>{errors.engineer_visited}</div>}

                        </Grid>
                       
                        <Grid item md={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                                <DatePicker
                                    label="Site Visited Date*"
                                    dateFormat="DD/MM/YYYY"
                                    format="DD/MM/YYYY"
                                    required
                                    slotProps={{ textField: { fullWidth: true, error: false, } }}
                                    name="site_visit_date"
                                    value={site_visit_date ? dayjs(site_visit_date) : null}
                                    maxDate={dayjs(maxDate)}
                                    onChange={(newValue) => {
                                        setSiteVisitDate(newValue)
                                        setErrors({ ...errors, site_visit_date: null });
                                        setDisableButton(false);
                                    }}
                                />
                            </LocalizationProvider>
                            {errors.site_visit_date && <div className='error-text'>{errors.site_visit_date}</div>}
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item md={6}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="demo-multiple-name-label">Site Visit Type *</InputLabel>
                                <Select
                                    value={form.site_visit_type || ''}
                                    name="site_visit_type"
                                    label="Site Visit Type"
                                    required
                                    fullWidth
                                    onChange={handleSelect}
                                >
                                    {siteVisitType?.map(item => (
                                        <MenuItem key={item} value={item}>{item}</MenuItem>
                                    ))}


                                </Select>
                                {errors.site_visit_type && <div className='error-text'>{errors.site_visit_type}</div>}
                            </FormControl>

                        </Grid>
                        <Grid item md={6}>
                            <Autocomplete
                                disabled={!(form.site_visit_type === 'Ticket')}
                                fullWidth
                                multiple
                                options={facilityTickets}
                                value={selectedFacilityTickets}
                                filterSelectedOptions
                                isOptionEqualToValue={(option, value) => option === value}
                                onChange={handleTicketSelect}
                                getOptionLabel={(option) => option}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <ListItemText primary={option} />
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} label="Ticket Numbers" placeholder="Ticket Numbers" />
                                )}
                            />
                            {errors.ticket_numbers && <div className='error-text'>{errors.ticket_numbers}</div>}
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        {dynamicFields?.map((field, index) => {
                            return <Paper square={false} elevation={4} key={index} sx={{ mt: 2 }} className="w3-padding">
                                {dynamicFields?.length > 1 && (
                                    <Tooltip title="Remove" className='w3-right' style={{ position: 'absolute', top: '8px', right: '6px' }}>
                                        <Grid id={`card_${index}`} item style={{ display: 'flex', justifyContent: "flex-end" }} className="cursor-pointer font-size-12 error-text" onClick={() => handleRemoveField(field.cardId)}>
                                            <CloseIcon name="remove" />
                                        </Grid>
                                    </Tooltip>
                                )}
                                <Grid item md={12} sx={{ mt: 2 }}>
                                    <TextField
                                        fullWidth
                                        name={`description${index}`}
                                        value={field.description || ''}
                                        label="Description"
                                        multiline
                                        required
                                        rows={3}
                                        onChange={(e) => handleDynamicFieldChange(index, 'description', e.target.value)}
                                        placeholder="Description"
                                    />
                                    {errors[`description${index}`] && <div className='error-text w3-margin-bottom'>{errors[`description${index}`]}</div>}
                                </Grid>
                                <Grid item md={12} sx={{ mt: 2, mb: 2 }}>
                                    <TextField
                                        fullWidth
                                        name={`responsibleTeamTimeline${index}`}
                                        value={field.responsibleTeamTimeline || ''}
                                        label="Responsible Team - Timeline"
                                        multiline
                                        rows={2}
                                        onChange={(e) => handleDynamicFieldChange(index, 'responsibleTeamTimeline', e.target.value)}
                                        placeholder="Responsible Team - Timeline"
                                    />
                                    {errors[`responsibleTeamTimeline${index}`] && <div className='error-text w3-margin-bottom'>{errors[`responsibleTeamTimeline${index}`]}</div>}
                                </Grid>
                                <Grid container item md={12} className='w3-margin-bottom'>
                                    <label htmlFor={`logo-file-${index}`}>
                                        <input
                                            style={{ display: 'none' }}
                                            required
                                            id={`logo-file-${index}`}
                                            name={`logoFile-${index}`}
                                            accept="image/jpeg, image/png, image/, 'image/heif'"
                                            type="file"
                                            multiple
                                            onChange={(event) => handleFileChange(event, field.cardId)}
                                        />
                                        <div style={{ display: 'flex' }}>
                                            <div>
                                                <Button
                                                    className="w3-margin-top"
                                                    color="primary"
                                                    variant="outlined"
                                                    size="medium"
                                                    component="span"
                                                >
                                                    Upload Image
                                                </Button>
                                            </div>
                                        </div>
                                    </label>
                                    {errors.uploadedImage && <div className='error-text w3-margin-bottom'>{errors.uploadedImage}</div>}
                                </Grid>
                                <Grid container item md={12}>
                                    {uploadedFile[field.cardId] && uploadedFile[field.cardId]?.map((image, idx) => (
                                        <Grid item key={idx} style={{ position: 'relative' }}>
                                            <img width="150px" style={{ padding: '5px' }} src={URL.createObjectURL(image)} alt={`Image ${idx}`} />
                                            <IconButton
                                                aria-label="delete"
                                                onClick={() => handleDeleteImage(field.cardId, idx)}
                                                style={{ position: 'absolute', top: '5px', right: '5px'}}
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </Grid>
                                    ))}

                                    {field?.images?.map((image, idx) => {
                                        const imageUrl = image.signedUrl
                                        return <Grid item key={idx} style={{ position: 'relative' }}>
                                            <img width="150px" style={{ padding: '5px' }} src={image?.signedUrl} alt={`Image ${idx}`} />
                                            <IconButton
                                                aria-label="delete"
                                                onClick={() => handleDeleteStoredImage(image)}
                                                style={{ position: 'absolute', top: '5px', right: '5px' }}
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </Grid>
                                    })}
                                </Grid>


                            </Paper>
                        })}
                        <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={12} sx={{ mt: 2 }} className="font-size-14">
                            <Button
                                variant="outlined"
                                onClick={(event) => {
                                    handleAddField();
                                    event.stopPropagation();
                                }}
                                size="small"
                                className='cursor-pointer'>
                                <AddIcon name="add" />
                                Add Description
                            </Button>

                        </Grid>
                    </Grid>


                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <Grid container className='w3-margin-bottom' spacing={2} justifyItems="space-between">
                            <Grid item md={6} style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '16px' }} className='w3-margin-bottom'>
                                {site_visit_id && (
                                    <Can do="manage" on="DeleteSiteVisitReport">

                                        <Button
                                            variant="outlined"
                                            startIcon={<DeleteIcon />}
                                            color="error"
                                            onClick={() => triggerDelete()}
                                        >
                                            Delete
                                        </Button>
                                    </Can>
                                )}
                            </Grid>
                            <Grid item container md={6} justifyContent="flex-end">
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        className="w3-margin-right"
                                        onClick={() => navigate(-1)}
                                    >
                                        Cancel
                                    </Button>
                                    <Can do="manage" on="EditSiteVisitReport">
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={handleSubmit}
                                            disabled={disableButton}
                                        >
                                            Submit
                                        </Button>
                                    </Can>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {alertMessage ? (
                <AlertDialog
                    handleClose={() => navigate(-1)}
                    message={alertMessage}
                    type={msgType}
                    handleDialogClose={() => setAlertMessage(null)}
                />) : null}

            {uploadModalMessage ? (
                <AlertDialog
                    handleClose={() => setUploadModalMessage(null)}
                    message={uploadModalMessage}
                    type={msgType}
                    handleDialogClose={() => setUploadModalMessage(null)}
                />) : null}
            {showConfirmDialog
                ? (
                    <ConfirmDialog
                        handleCancel={() => {
                            setSelectedItem({})
                            setShowConfirmDialog(false)
                        }}
                        handleOk={handleDeleteSiteVisitReport}
                        message={'Are you sure to delete this report ?'}
                    />
                ) : null}
            {showConfirmUploadDialog
                ? (
                    <ConfirmDialog
                        handleCancel={() => {
                            setShowConfirmUploadDialog(false)
                        }}
                        handleOk={handleConfirmUpload}
                        message={'Are you sure to Upload Image?'}
                    />
                ) : null}
        </Grid>
    </Paper >
    )
}