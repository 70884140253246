import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid } from '@mui/material';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { getOpenaiResponse, getOpenaiResponseForImageUrl } from '../../Redux/actions';
import * as htmlToImage from 'html-to-image';
import { useDispatch } from 'react-redux';

const InsightsModal = ({ 
  openInsight, 
  closeInsight, 
  domRef, 
  setShowInsightButton,
  convertedJson,
  insightKey,
  endDateOfTrndAnalys,
  startDateOfTrndAnalys}) => {

  const dispatch = useDispatch();
  const [insights, setInsights] = useState([]);
  const [insightLoader, setInsightLoader] = useState(true);

  useEffect(() => {
    if(!openInsight){
      return
    }
      setInsightLoader(true);
      setTimeout( () => {handleViewInsights()}, 500)
    
  },[openInsight])

  const handleViewInsights = () => {
    if (domRef) {
        htmlToImage.toPng(domRef?.current ? domRef?.current : domRef)
            .then(function (dataUrl) {
                dispatch(getOpenaiResponseForImageUrl({ dataUrl, insightKey, endDateOfTrndAnalys, startDateOfTrndAnalys })).then((res) => {
                    setInsights(res?.data?.data);
                    setInsightLoader(false);
                    setShowInsightButton();
                })
            })
            .catch(function (error) {
              setShowInsightButton();
              console.error('Error converting to image:', error);
            });
    } else if ( convertedJson ) {
        dispatch(getOpenaiResponse(convertedJson, insightKey))
          .then((res) => {
              setInsights(res?.data?.data);
              setInsightLoader(false);
              setShowInsightButton();
          })
          .catch(function (error) {
              setShowInsightButton();
              console.error('Error fetching insights:', error);
          });
    }
  };   

  const handleCloseModal = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }
      setInsights([]);
      closeInsight();
  }


  let content = null;

  if (insightLoader) {
    content = (
      <Grid container display='flex' flexDirection='column' style={{ alignItems: 'center', maxHeight: '600px', justifyContent: 'center' }}>
        <Grid style={{ height: '100px' }}>
          <div className="loader"></div>
        </Grid>
        <Grid item container justifyContent="center">
          <Grid item>
            <p style={{ color: '#cccccc' }}>Our AI-Engine is analysing the graph and genarating insights for you. Please wait...</p>
          </Grid>
          <Grid item>
            <p style={{ color: '#cccccc' }}>This may take few seconds.</p>
          </Grid>
        </Grid>
      </Grid>
    );
  } else if (insights?.length > 0) {
    content = (<div>

      {insights.map(insight => (
        <p>  <TipsAndUpdatesIcon color="warning" style={{marginRight:'10px'}}/> {insight.description}</p>
      ))}
      <DialogActions>
        <Button onClick={handleCloseModal} variant="contained">
          Close
        </Button>
      </DialogActions>
    </div>
    );
  } else if (!insights){
    content = (
      <Grid container display='flex' flexDirection='column' style={{ alignItems: 'center', maxHeight: '600px', justifyContent: 'center' }}>
        <Grid item container justifyContent="center" flexDirection="column" alignItems="center" >
          <Grid item>
            <p style={{ color: '#cccccc' }}>Something went wrong, Please try again.</p>
          </Grid>
          <Grid item>
            <Button variant='contained' onClick={handleCloseModal}>Close</Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <Dialog open={openInsight} onClose={handleCloseModal}>
     
      <DialogContent className="dlg-cont" style={{
        color:'white',
        backgroundImage: 'linear-gradient(to right, #0e1c75 0%, #411377 100%)'
      }}>
         <DialogTitle style={{ fontSize: '10px', textAlign: 'center', color: '#CCCCCC' }}>
        *This information is generated by AI. AI can make mistakes. Consider checking important information.
      </DialogTitle>
        {content}
      </DialogContent>

    </Dialog >
  );
};

export default InsightsModal;
